import resolveConfig from 'tailwindcss/resolveConfig';
import Cache from './cache';
import ClassParser from './ClassParser';
import { parseInputs } from './parse-inputs';
import { complete, warn } from './helpers';
import { getAddedUtilities } from './plugin';
import { removeOpacityHelpers } from './resolve/color';
export function create(customConfig, platform) {
    const config = resolveConfig(withContent(customConfig));
    const device = {};
    const pluginUtils = getAddedUtilities(config.plugins);
    const customStringUtils = {};
    const customStyleUtils = Object.entries(pluginUtils)
        .map(([util, style]) => {
        if (typeof style === `string`) {
            // mutating while mapping, i know - bad form, but for performance sake... ¯\_(ツ)_/¯
            customStringUtils[util] = style;
            return [util, { kind: `null` }];
        }
        return [util, complete(style)];
    })
        .filter(([, ir]) => ir.kind !== `null`);
    patchCustomFontUtils(customConfig, customStyleUtils, config);
    function deriveCacheGroup() {
        return ([
            device.windowDimensions ? `w${device.windowDimensions.width}` : false,
            device.windowDimensions ? `h${device.windowDimensions.height}` : false,
            device.fontScale ? `fs${device.fontScale}` : false,
            device.colorScheme === `dark` ? `dark` : false,
            device.pixelDensity === 2 ? `retina` : false,
        ]
            .filter(Boolean)
            .join(`--`) || `default`);
    }
    let cacheGroup = deriveCacheGroup();
    const contextCaches = {};
    function getCache() {
        const existing = contextCaches[cacheGroup];
        if (existing) {
            return existing;
        }
        const cache = new Cache(customStyleUtils);
        contextCaches[cacheGroup] = cache;
        return cache;
    }
    function style(...inputs) {
        const cache = getCache();
        let resolved = {};
        const dependents = [];
        const ordered = [];
        const [utilities, userStyle] = parseInputs(inputs);
        // check if we've seen this full set of classes before
        // if we have a cached copy, we can skip examining each utility
        const joined = utilities.join(` `);
        const cached = cache.getStyle(joined);
        if (cached) {
            return { ...cached, ...(userStyle ? userStyle : {}) };
        }
        for (const utility of utilities) {
            let styleIr = cache.getIr(utility);
            if (!styleIr && utility in customStringUtils) {
                const customStyle = style(customStringUtils[utility]);
                cache.setIr(utility, complete(customStyle));
                resolved = { ...resolved, ...customStyle };
                continue;
            }
            const parser = new ClassParser(utility, config, cache, device, platform);
            styleIr = parser.parse();
            switch (styleIr.kind) {
                case `complete`:
                    resolved = { ...resolved, ...styleIr.style };
                    cache.setIr(utility, styleIr);
                    break;
                case `dependent`:
                    dependents.push(styleIr);
                    break;
                case `ordered`:
                    ordered.push(styleIr);
                    break;
                case `null`:
                    cache.setIr(utility, styleIr);
                    break;
            }
        }
        if (ordered.length > 0) {
            ordered.sort((a, b) => a.order - b.order);
            for (const orderedStyle of ordered) {
                switch (orderedStyle.styleIr.kind) {
                    case `complete`:
                        resolved = { ...resolved, ...orderedStyle.styleIr.style };
                        break;
                    case `dependent`:
                        dependents.push(orderedStyle.styleIr);
                        break;
                }
            }
        }
        if (dependents.length > 0) {
            for (const dependent of dependents) {
                const error = dependent.complete(resolved);
                if (error) {
                    warn(error);
                }
            }
            removeOpacityHelpers(resolved);
        }
        // cache the full set of classes for future re-renders
        // it's important we cache BEFORE merging in userStyle below
        if (joined !== ``) {
            cache.setStyle(joined, resolved);
        }
        if (userStyle) {
            resolved = { ...resolved, ...userStyle };
        }
        return resolved;
    }
    function color(utils) {
        const styleObj = style(utils
            .split(/\s+/g)
            .map((util) => util.replace(/^(bg|text|border)-/, ``))
            .map((util) => `bg-${util}`)
            .join(` `));
        return typeof styleObj.backgroundColor === `string`
            ? styleObj.backgroundColor
            : undefined;
    }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const tailwindFn = (strings, ...values) => {
        let str = ``;
        strings.forEach((string, i) => {
            var _a;
            str += string + ((_a = values[i]) !== null && _a !== void 0 ? _a : ``);
        });
        return style(str);
    };
    tailwindFn.style = style;
    tailwindFn.color = color;
    tailwindFn.prefixMatch = (...prefixes) => {
        const joined = prefixes.sort().join(`:`);
        const cache = getCache();
        const cached = cache.getPrefixMatch(joined);
        if (cached !== undefined) {
            return cached;
        }
        const parser = new ClassParser(`${joined}:flex`, config, cache, device, platform);
        const ir = parser.parse();
        const prefixMatches = ir.kind !== `null`;
        cache.setPrefixMatch(joined, prefixMatches);
        return prefixMatches;
    };
    tailwindFn.setWindowDimensions = (newDimensions) => {
        device.windowDimensions = newDimensions;
        cacheGroup = deriveCacheGroup();
    };
    tailwindFn.setFontScale = (newFontScale) => {
        device.fontScale = newFontScale;
        cacheGroup = deriveCacheGroup();
    };
    tailwindFn.setPixelDensity = (newPixelDensity) => {
        device.pixelDensity = newPixelDensity;
        cacheGroup = deriveCacheGroup();
    };
    tailwindFn.setColorScheme = (newColorScheme) => {
        device.colorScheme = newColorScheme;
        cacheGroup = deriveCacheGroup();
    };
    return tailwindFn;
}
export default create;
function withContent(config) {
    return {
        ...config,
        // prevent warnings from tailwind about not having a `content` prop
        // we don't need one because we have our own jit parser which
        // does not rely on knowing content paths to search
        content: [`_no_warnings_please`],
    };
}
// Allow override default font-<name> style
// @TODO: long-term, i'd like to think of a more generic way to allow
// custom configurations not to get masked by default utilities...
function patchCustomFontUtils(customConfig, customStyleUtils, config) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    if (((_a = customConfig.theme) === null || _a === void 0 ? void 0 : _a.fontWeight) || ((_c = (_b = customConfig.theme) === null || _b === void 0 ? void 0 : _b.extend) === null || _c === void 0 ? void 0 : _c.fontWeight)) {
        [
            ...Object.entries((_e = (_d = customConfig.theme) === null || _d === void 0 ? void 0 : _d.fontWeight) !== null && _e !== void 0 ? _e : {}),
            ...Object.entries((_h = (_g = (_f = customConfig.theme) === null || _f === void 0 ? void 0 : _f.extend) === null || _g === void 0 ? void 0 : _g.fontWeight) !== null && _h !== void 0 ? _h : {}),
        ].forEach(([name, value]) => {
            customStyleUtils.push([`font-${name}`, complete({ fontWeight: String(value) })]);
        });
    }
    if (`object` === typeof ((_j = config.theme) === null || _j === void 0 ? void 0 : _j.fontFamily)) {
        [
            ...Object.entries((_l = (_k = customConfig.theme) === null || _k === void 0 ? void 0 : _k.fontFamily) !== null && _l !== void 0 ? _l : {}),
            ...Object.entries((_p = (_o = (_m = customConfig.theme) === null || _m === void 0 ? void 0 : _m.extend) === null || _o === void 0 ? void 0 : _o.fontFamily) !== null && _p !== void 0 ? _p : {}),
        ].forEach(([name, value]) => {
            const fontFamily = Array.isArray(value) ? value[0] : value;
            if (fontFamily) {
                customStyleUtils.push([`font-${name}`, complete({ fontFamily })]);
            }
        });
    }
}
